/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    blockquote: "blockquote",
    span: "span",
    math: "math",
    semantics: "semantics",
    mrow: "mrow",
    mi: "mi",
    mo: "mo",
    annotation: "annotation",
    mn: "mn"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Como me estaba cansando de cuando reinstalaba Kubuntu tener que volver a instalar y configurar a mano todo SO, me dio la idea mi colega Toni de tener un script que te lo configurara todo automatico. El resultado es esta primera versión del Litoxconf. Lo podeis guardar como litoxconf-1.0.sh y ejecutarlo con:"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "$ sudo ./litoxconf-1.0.sh"), "\n"), "\n", React.createElement(_components.p, null, "Este es el contenido del script:"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "#!/bin/bash"), "\n", React.createElement(_components.p, null, "#Actualizacion aptitude update aptitude dist-upgrade"), "\n", React.createElement(_components.p, null, "# Programas basicos aptitude install amsn firefox thunderbird mplayer"), "\n", React.createElement(_components.p, null, "# Codecs y pluggins aptitude install mozilla-mplayer rar unrar wine flashplugin-nonfree"), "\n", React.createElement(_components.p, null, "# Configuracion del escritorio y Compiz Fusion aptitude install compizconfig-settings-manager emerald fusion-icon"), "\n", React.createElement(_components.p, null, "echo ”## Pluggins extra Compiz Fusion” | tee -a /etc/apt/sources.list echo “deb http://ppa.launchpad.net/compiz/ubuntu hardy main” | tee -a /etc/apt/sources.list echo “deb-src http://ppa.launchpad.net/compiz/ubuntu hardy main” | tee -a /etc/apt/sources.list"), "\n", React.createElement(_components.p, null, "aptitude install compiz-fusion-plugins-main compiz-fusion-plugins-extra"), "\n", React.createElement(_components.p, null, "#Scripts de inicio"), "\n", React.createElement(_components.p, null, "echo ”#!/bin/bash” | tee -a ", React.createElement(_components.span, {
    className: "math math-inline"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.mi, null, "H"), React.createElement(_components.mi, null, "O"), React.createElement(_components.mi, null, "M"), React.createElement(_components.mi, null, "E"), React.createElement(_components.mi, {
    mathvariant: "normal"
  }, "/"), React.createElement(_components.mi, {
    mathvariant: "normal"
  }, "."), React.createElement(_components.mi, null, "k"), React.createElement(_components.mi, null, "d"), React.createElement(_components.mi, null, "e"), React.createElement(_components.mi, {
    mathvariant: "normal"
  }, "/"), React.createElement(_components.mi, null, "A"), React.createElement(_components.mi, null, "u"), React.createElement(_components.mi, null, "t"), React.createElement(_components.mi, null, "o"), React.createElement(_components.mi, null, "s"), React.createElement(_components.mi, null, "t"), React.createElement(_components.mi, null, "a"), React.createElement(_components.mi, null, "r"), React.createElement(_components.mi, null, "t"), React.createElement(_components.mi, {
    mathvariant: "normal"
  }, "/"), React.createElement(_components.mi, null, "f"), React.createElement(_components.mi, null, "u"), React.createElement(_components.mi, null, "s"), React.createElement(_components.mi, null, "i"), React.createElement(_components.mi, null, "o"), React.createElement(_components.mi, null, "n"), React.createElement(_components.mi, {
    mathvariant: "normal"
  }, "."), React.createElement(_components.mi, null, "s"), React.createElement(_components.mi, null, "h"), React.createElement(_components.mi, null, "e"), React.createElement(_components.mi, null, "c"), React.createElement(_components.mi, null, "h"), React.createElement(_components.mi, null, "o"), React.createElement(_components.mi, {
    mathvariant: "normal"
  }, "\""), React.createElement(_components.mi, null, "f"), React.createElement(_components.mi, null, "u"), React.createElement(_components.mi, null, "s"), React.createElement(_components.mi, null, "i"), React.createElement(_components.mi, null, "o"), React.createElement(_components.mi, null, "n"), React.createElement(_components.mo, null, "−"), React.createElement(_components.mi, null, "i"), React.createElement(_components.mi, null, "c"), React.createElement(_components.mi, null, "o"), React.createElement(_components.mi, null, "n"), React.createElement(_components.mi, {
    mathvariant: "normal"
  }, "\""), React.createElement(_components.mi, {
    mathvariant: "normal"
  }, "∣"), React.createElement(_components.mi, null, "t"), React.createElement(_components.mi, null, "e"), React.createElement(_components.mi, null, "e"), React.createElement(_components.mo, null, "−"), React.createElement(_components.mi, null, "a")), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "HOME/.kde/Autostart/fusion.sh echo \"fusion-icon\" | tee -a ")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "1em",
      verticalAlign: "-0.25em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.08125em"
    }
  }, "H"), React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.02778em"
    }
  }, "O"), React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.10903em"
    }
  }, "M"), React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.05764em"
    }
  }, "E"), React.createElement(_components.span, {
    className: "mord"
  }, "/"), React.createElement(_components.span, {
    className: "mord"
  }, "."), React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.03148em"
    }
  }, "k"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "d"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "e"), React.createElement(_components.span, {
    className: "mord"
  }, "/"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "A"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "u"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "t"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "o"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "s"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "t"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "a"), React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.02778em"
    }
  }, "r"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "t"), React.createElement(_components.span, {
    className: "mord"
  }, "/"), React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.10764em"
    }
  }, "f"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "u"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "s"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "i"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "o"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "n"), React.createElement(_components.span, {
    className: "mord"
  }, "."), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "s"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "h"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "e"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "c"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "h"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "o"), React.createElement(_components.span, {
    className: "mord"
  }, "\""), React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.10764em"
    }
  }, "f"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "u"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "s"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "i"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "o"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "n"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222222222222222em"
    }
  }), React.createElement(_components.span, {
    className: "mbin"
  }, "−"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222222222222222em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "1em",
      verticalAlign: "-0.25em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "i"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "c"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "o"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "n"), React.createElement(_components.span, {
    className: "mord"
  }, "\""), React.createElement(_components.span, {
    className: "mord"
  }, "∣"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "t"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "e"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "e"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222222222222222em"
    }
  }), React.createElement(_components.span, {
    className: "mbin"
  }, "−"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222222222222222em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.43056em",
      verticalAlign: "0em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "a"))))), "HOME/.kde/Autostart/fusion.sh chmod 777 ", React.createElement(_components.span, {
    className: "math math-inline"
  }, React.createElement(_components.span, {
    className: "katex-error",
    title: "ParseError: KaTeX parse error: Expected 'EOF', got '#' at position 37: …usion.sh echo \"#̲!/bin/bash\" | t…",
    style: {
      color: "#cc0000"
    }
  }, "HOME/.kde/Autostart/fusion.sh echo \"#!/bin/bash\" | tee -a ")), "HOME/.kde/Autostart/amsn.sh echo “amsn” | tee -a ", React.createElement(_components.span, {
    className: "math math-inline"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.mi, null, "H"), React.createElement(_components.mi, null, "O"), React.createElement(_components.mi, null, "M"), React.createElement(_components.mi, null, "E"), React.createElement(_components.mi, {
    mathvariant: "normal"
  }, "/"), React.createElement(_components.mi, {
    mathvariant: "normal"
  }, "."), React.createElement(_components.mi, null, "k"), React.createElement(_components.mi, null, "d"), React.createElement(_components.mi, null, "e"), React.createElement(_components.mi, {
    mathvariant: "normal"
  }, "/"), React.createElement(_components.mi, null, "A"), React.createElement(_components.mi, null, "u"), React.createElement(_components.mi, null, "t"), React.createElement(_components.mi, null, "o"), React.createElement(_components.mi, null, "s"), React.createElement(_components.mi, null, "t"), React.createElement(_components.mi, null, "a"), React.createElement(_components.mi, null, "r"), React.createElement(_components.mi, null, "t"), React.createElement(_components.mi, {
    mathvariant: "normal"
  }, "/"), React.createElement(_components.mi, null, "a"), React.createElement(_components.mi, null, "m"), React.createElement(_components.mi, null, "s"), React.createElement(_components.mi, null, "n"), React.createElement(_components.mi, {
    mathvariant: "normal"
  }, "."), React.createElement(_components.mi, null, "s"), React.createElement(_components.mi, null, "h"), React.createElement(_components.mi, null, "c"), React.createElement(_components.mi, null, "h"), React.createElement(_components.mi, null, "m"), React.createElement(_components.mi, null, "o"), React.createElement(_components.mi, null, "d"), React.createElement(_components.mn, null, "777")), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "HOME/.kde/Autostart/amsn.sh chmod 777 ")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "1em",
      verticalAlign: "-0.25em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.08125em"
    }
  }, "H"), React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.02778em"
    }
  }, "O"), React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.10903em"
    }
  }, "M"), React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.05764em"
    }
  }, "E"), React.createElement(_components.span, {
    className: "mord"
  }, "/"), React.createElement(_components.span, {
    className: "mord"
  }, "."), React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.03148em"
    }
  }, "k"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "d"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "e"), React.createElement(_components.span, {
    className: "mord"
  }, "/"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "A"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "u"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "t"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "o"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "s"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "t"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "a"), React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.02778em"
    }
  }, "r"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "t"), React.createElement(_components.span, {
    className: "mord"
  }, "/"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "a"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "m"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "s"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "n"), React.createElement(_components.span, {
    className: "mord"
  }, "."), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "s"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "h"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "c"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "h"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "m"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "o"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "d"), React.createElement(_components.span, {
    className: "mord"
  }, "7"), React.createElement(_components.span, {
    className: "mord"
  }, "7"), React.createElement(_components.span, {
    className: "mord"
  }, "7"))))), "HOME/.kde/Autostart/amsn.sh"), "\n"), "\n", React.createElement(_components.p, null, "Falta aún pulirlo y añadir nuevas funcionalidades, como por ejemplo que si lo ejecutas varias veces no te reescriba cosas."), "\n", React.createElement(_components.p, null, "Animo a todo el mundo a adaptarlo a sus necesidades y a proponer mejoras para hacerlo mas funcional."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
